<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-avatar>
      <v-icon color="white" size="28" @click="mini = !mini">mdi-menu</v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h4">
        <strong class="mr-1 font-weight-black">Al-jabr</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { sync, get } from 'vuex-pathify'
// Utilities

export default {
  name: "DefaultDrawerHeader",
  computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      name: get('route/name'),
    },
};
</script>
